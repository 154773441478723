import React from 'react';
import { Link } from '../link';
import Icon from '../../atoms/icon';
import './LateralSticker.scss';
import { Wrapper } from '../../utils/components';
import slugify from 'slugify';
import withPreview from '../../utils/with-preview';

function LateralSticker({ data }) {
  return (
    <article
      className='lateral-sticker'
      data-module-name='lateral-sticker'
      data-module-code='C-34'
    >
      <Wrapper
        component={data.link?.value && Link}
        onClick={(event) => {
          event.preventDefault();
          const href = event.currentTarget.getAttribute('href') || '';
          if (href.startsWith('#')) {
            const id = href.substring(1); // takes every character after '#'
            const target = document.getElementById(id);
            if (target) {
              target.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }}
        to={
          data.link?.value
            ? `#${slugify(data.link.value, { lower: true, strict: true })}`
            : null
        }
        className='lateral-sticker-link'
        tag='section'
      >
        {data.iconSvgId?.value && (
          <span className='lateral-sticker-svg'>
            <Icon
              icon={data?.iconSvgId?.value}
              thickness={data?.iconSvgId?.content?.iconThickness?.value[0]}
            />
          </span>
        )}
        {data.label?.value && (
          <span className='lateral-sticker-label'>{data.label.value}</span>
        )}
      </Wrapper>
    </article>
  );
}

export default withPreview(LateralSticker);
